<template>
  <v-dialog v-model="showingModal" persistent fullscreen hide-overlay>
    <v-card>
      <v-app-bar class="elevation-0 instrument-app-bar">
        <v-app-bar-title class="text-h6 grey text-no-wrap">{{$t('Reportes')}}</v-app-bar-title>
        <v-spacer></v-spacer>
        <span >{{isCountry ? $t('País') : $t('Region')}}: {{ item.name ? item.name : ' - '}}</span>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <v-btn :outlined="$vuetify.theme.dark" :text="$vuetify.theme.dark" color="success" class="mr-1" @click.stop="$refs.report_form_modal.openModal(item, isCountry)">
            {{ $t('Crear reporte') }}
            <v-icon class="ml-2">{{ icons.mdiPlus }}</v-icon>
          </v-btn>
          <v-btn :outlined="$vuetify.theme.dark" :text="$vuetify.theme.dark" color="info" @click="printFullSection()">
            {{ $t('Imprimir sección') }}
            <v-icon class="ml-2">{{ icons.mdiPrinter }}</v-icon>
          </v-btn>
          <v-btn icon color="warning" :aria-label="$t('Ayuda')" @click.stop="$refs.print_helper_modal.openModal()">
            <v-icon>{{ icons.mdiHelpCircleOutline }}</v-icon>
          </v-btn>
        </div>
        <v-btn icon color="tertiary" @click="close()" aria-label="close-modal">
          <v-icon>{{icons.mdiClose}}</v-icon>
        </v-btn>
      </v-app-bar>

      <v-row no-gutters>
        <v-col class="px-12">
          <v-card class="mt-5" color="f6faff">
            <div v-if="reportsList.length && !loading" ref="print_reports" class="page-break">
              <div v-for="(report, idx) in reportsList" class="report-section page-break" :id="`print_report${idx}`" :key="`print_report_${idx}`">
                <v-row>
                  <v-col 
                  class="py-0 text-body-1 base-row" 
                  :class="$vuetify.theme.dark ? 'row-dark' : 'row-light'" 
                  style="position:relative"
                  >
                    <v-card-title class="justify-left pa-4 text-h6"> 
                      {{ $t('Reporte') + ' ' + (report.created_at.split("T")[0]) }} 
                    </v-card-title>
                    <div style="position: absolute; top: 0px; right: 1rem;">
                      <v-btn
                        small
                        text
                        v-if="!toEdit[`report${idx}`]"
                        :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                        class="me-2 mr-1 icon-with-label small no-print"
                        aria-label="edit"
                        style="padding: 29px"
                        @click="toEditReport(idx)"
                      >
                        <span class="label">{{ $t('Editar') }}</span>
                        <v-icon >{{ icons.mdiPencilOutline }}</v-icon>
                      </v-btn>
                      <v-menu offset-x bottom v-if="(isCountry && (userRole === 0 || userRole === 1)) || (!isCountry && userRole !== 3)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="me-2 icon-with-label small no-print"
                            color="error"
                            text
                            small
                            style="padding: 29px"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <div class="d-flex align-center">
                              <span class="label">
                                {{ $t('Eliminar') }}
                              </span>
                              <v-icon size="1.5rem">
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                            </div>
                          </v-btn>

                        </template>
                        <v-card :color="$vuetify.theme.dark ? '#373258' : '#f6faff'" elevation="8">
                          <v-card-subtitle> <b> {{ $t('¿Estás seguro de eliminar este reporte') + '?' }}</b></v-card-subtitle>
                          <v-card-text class="text-center">

                            <v-btn
                              small
                              outlined
                              color="tertiary"
                              class="me-3"
                            >
                              {{ $t('Cancelar') }}
                            </v-btn>
                            <v-btn
                              small
                              color="error"
                              :outlined="$vuetify.theme.dark"
                              @click="deleteReport(report)"
                            >
                              {{ $t('Eliminar') }}
                            </v-btn>

                          </v-card-text>
                        </v-card>
                      </v-menu>
                      <v-btn
                        small
                        text
                        color="info"
                        class="me-2 mr-1 icon-with-label small no-print"
                        aria-label="print"
                        style="padding: 29px"
                        @click="printReport(idx)"
                      >
                        <span class="label">{{ $t('Imprimir') }}</span>
                        <v-icon >{{ icons.mdiPrinter }}</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row v-if="!toEdit[`report${idx}`]">
                  <v-col cols="12" class="pt-2 pb-0 d-flex justify-end">
                    <span class="text-subtitle-2">{{ $t('Fecha') }}: {{ report.created_at.split("T")[0] }}</span>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-card-subtitle class="font-weight-bold pb-1">{{ $t('Acuerdos') + ':' }}</v-card-subtitle>
                    <v-card-text>
                      <span v-if="report.agreements">
                        {{ report.agreements }}
                      </span>
                      <span v-if="!report.agreements" class="font-italic">
                        {{ `${$t('No hay información aun cargada')}` }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <v-card-subtitle class="font-weight-bold pb-1">{{ $t('Resultado esperado') + ':' }}</v-card-subtitle>
                    <v-card-text>
                      <span v-if="report.expected_result && report.expected_result.length">
                        {{ report.expected_result }}
                      </span>
                      <span v-else class="font-italic">
                        {{ `${$t('No hay información aun cargada')}` }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <v-card-subtitle class="font-weight-bold pb-1">{{ $t('Nota') + ':' }}</v-card-subtitle>
                    <v-card-text>
                      <span v-if="report.note && report.note.length">
                        {{ report.note }}
                      </span>
                      <span v-else class="font-italic">
                        {{ `${$t('No hay información aun cargada')}` }}
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col cols="12" class="pt-2 pb-0 d-flex justify-end">
                    <span class="text-subtitle-2">{{ $t('Fecha') }}: {{ report.created_at.split("T")[0] }}</span>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-card-subtitle class="font-weight-bold pb-1">{{ $t('Acuerdos') + ':' }} {{ toEdit[`report${idx}`] }}</v-card-subtitle>
                    <v-card-text>
                      <v-textarea
                      v-model="report.agreements"
                      outlined
                      name="input-7-4"
                      :label="$t('Editando') + '...'"
                      rows="3"
                      hide-details
                      ></v-textarea>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <v-card-subtitle class="font-weight-bold pb-1">{{ $t('Resultado esperado') + ':' }}</v-card-subtitle>
                    <v-card-text>
                      <v-textarea
                      v-model="report.expected_result"
                      outlined
                      name="input-7-4"
                      :label="$t('Editando') + '...'"
                      rows="3"
                      hide-details
                      ></v-textarea>
                    </v-card-text>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <v-card-subtitle class="font-weight-bold pb-1">{{ $t('Nota') + ':' }}</v-card-subtitle>
                    <v-card-text>
                      <v-textarea
                      v-model="report.note"
                      outlined
                      name="input-7-4"
                      :label="$t('Editando') + '...'"
                      rows="3"
                      hide-details
                      ></v-textarea>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end mb-3">
                    <v-btn
                      small
                      outlined
                      color="tertiary"
                      @click="cancelEditingReport(idx)"
                    >
                      {{ $t('Cancelar') }}
                    </v-btn>
                    <v-btn
                      small
                      :outlined="$vuetify.theme.dark"
                      :color="$vuetify.theme.dark ? 'warning' : 'primary'"
                      class="ms-2"
                      @click="editReport(report)"
                    >
                      {{ $t('Guardar') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-pagination class="no-print" v-model="currentPage" :length="totalReports"></v-pagination>
            </div>
            <v-card-text v-if="!reportsList.length && !loading" :class="$vuetify.theme.dark ? 'row-dark' : 'row-light'" class="text-center">
              <h3 class="mt-3 mb-2"><b>{{ isCountry ? $t('No hay reportes asignados para este país.') : $t('No hay reportes asignados para esta región.') }}</b></h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <ReportFormModal ref="report_form_modal" @reload-report-list="getReports"/>
    <PrintHelperModal ref="print_helper_modal"/>
  </v-dialog>
</template>

<script>
import { Printd } from 'printd'
import {ref} from '@vue/composition-api'
import countriesParserMixin from '@/utils/countriesParserMixin';
import PrintHelperModal from '@/components/Evaluations/PrintHelperModal.vue';
import ReportFormModal from './ReportFormModal.vue'
import { 
  mdiClose, 
  mdiHelpCircleOutline, 
  mdiPencilOutline, 
  mdiTrashCanOutline, 
  mdiPlus, 
  mdiPrinter, 
} from '@mdi/js'

export default {
  data: () => {
    return {
      showingModal: false,
      isCountry: false,
      item: {},
      toEdit: {},
      loading: false,
      totalReports: 0,
      currentPage: 1,
      itemsPerPage: 10,
      generalDescription: null
    }
  },
  mixins: [countriesParserMixin],
  methods: {
    openModal(item, is_country) {
      this.isCountry = is_country
      this.item = item
      this.copyGeneralDescription()
      this.showingModal = true
      this.getReports()
    },
    close() {
      this.isCountry = false
      this.item = {}
      this.showingModal = false
    },
    async getReports() {
      this.loading = true
      try {
        let form = {
          country: this.isCountry ?? false,
          id: this.item.id,
          page: this.currentPage,
          page_size: this.itemsPerPage
        }
        const response = await this.$api.getReports(form)
        this.reportsList = response.results
        this.totalReports = Math.ceil(response.count/this.itemsPerPage)
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    toEditReport(idx) {
      this.$set(this.toEdit, `report${idx}`, true)
    },
    cancelEditingReport(idx) {
      this.$set(this.toEdit, `report${idx}`, false)
    },
    async editReport(report) {
      try {
        let form = {
          agreements: report.agreements,
          note: report.note,
          expected_result: report.expected_result,
        }

        const response = await this.$api.editReport(form, report.id)
        this.toEdit = {}
        this.getReports()
      } catch(e) {
        console.log(e)
      }
    },
    async deleteReport(report) {
      try {
        const response = await this.$api.deleteReport(report.id)
        this.getReports()
      } catch(e) {
        console.log(e)
      }
    },
    copyGeneralDescription() {
      const node = document.createElement('div');

      let printData = [`${this.isCountry ? this.$t('País') : this.$t('Region')}: ${this.item.name}`]

      printData.forEach(item => {
        const span = document.createElement('span');
        span.textContent = item;
        node.appendChild(span);
      });

      node.classList.add('general-description')
      this.generalDescription = node
    },
    printFullSection() {
      let printContainer = document.createElement('div');
      printContainer.appendChild(this.generalDescription);

      let reports = this.$refs.print_reports.cloneNode(true)
      printContainer.appendChild(reports)
      this.printer.print(printContainer, [this.printCss])
    },

    printReport(idx) {
      let printContainer = document.createElement('div');
      printContainer.appendChild(this.generalDescription);
      let report = document.getElementById(`print_report${idx}`).cloneNode(true)
      printContainer.appendChild(report)
      this.printer.print(printContainer, [this.printCss])
    },
  },
  watch: {
    currentPage(newValue) {
      if(newValue) {
        this.getReports()
      }
    }
  },
  mounted() {
    //initialize printd
    this.printer = new Printd()
  },
  computed: {
    userRole() { return this.$store.getters['session/getUserRole']() },
  },
  components: {
    PrintHelperModal, ReportFormModal
  },
  setup() {
    const icons = {
      mdiClose, 
      mdiHelpCircleOutline, 
      mdiPencilOutline, 
      mdiTrashCanOutline, 
      mdiPlus, 
      mdiPrinter,
    }

    const reportsList = ref([])

    const printCss = `
      .general-description {
          display:flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 15px;
          font-family: "AvenirNext", "Inter", sans-serif !important;
          font-size: 15px !important;
        }

        h2 {
          font-family: "AvenirNext", "Inter", sans-serif !important;
          font-size: 24px;
        }

        .v-card__title{
          font-weight: 700;
          font-size: 24px;
        }

        .v-card__subtitle {
          font-weight: 600;
          margin-top: 5px;
          margin-bottom: 5px
        }

        .report-section {
          margin-bottom: 15px
        }

        .no-print {
          display: none
        }
      `

    return {
      icons,
      reportsList,
      printCss
    }
  }
}

</script>

<style lang="scss" scoped>
.status-select {
  max-width: 10rem;
}

.base-row {
  border: 1px solid rgba(94, 86, 105, 0.14);
}

// Clases específicas con diferencias de color
.row-light {
  @extend .base-row;
  background-color: #e7f1fd;
}

.row-dark {
  @extend .base-row;
  background-color: #4a456a;
}
</style>