<template>
  <v-dialog v-model="showingReportModal" width="900" scrollable persistent minHeight="1200">
    <v-form ref="reportForm" autocomplete="new-password" @submit.prevent="createReport">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 pb-0">
          {{ $t('Crear reporte') }}
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="4">
              <v-textarea
              v-model="form.agreements"
              outlined
              name="input-7-4"
              :label="$t('Acuerdos') + '...'"
              rows="3"
              hide-details
              ></v-textarea>
            </v-col>

            <v-col cols="4">
              <v-textarea
              v-model="form.expected_result"
              outlined
              name="input-7-4"
              :label="$t('Resultado esperado') + '...'"
              rows="3"
              hide-details
              ></v-textarea>
            </v-col>

            <v-col cols="4">
              <v-textarea
              v-model="form.note"
              outlined
              name="input-7-4"
              :label="$t('Nota') + '...'"
              rows="3"
              hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="terciary"
            outlined
            @click="close()"
          >
            {{$t('Cancelar')}}
          </v-btn>
          <v-btn color="primary" type="submit">
            {{ $t('Crear reporte') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data:() => {
    return {
      form: {
        agreements: '',
        expected_result: '',
        note: '',
      },
      showingReportModal: false,
      item: null,
      isCountry: false
    }
  },
  methods: {
    openModal(item, is_country) {
      this.item = item
      this.isCountry = is_country
      this.showingReportModal = true
    },
    close() {
      this.$refs.reportForm.reset()
      this.item = null
      this.showingReportModal = false
      this.$nextTick(function () {
        document.documentElement.classList.add('overflow-y-hidden');
      })
    },
    async createReport() {
      try {
        let data = {
          id: this.item.id,
          isCountry: this.isCountry
        }
        const response = await this.$api.createReport(this.form, data)
        this.$forceUpdate()
        this.$emit("reload-report-list");
        this.close()
      } catch(e) {
        console.log(e)
      }
    }
  }
}
</script>