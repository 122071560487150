import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"900","scrollable":"","persistent":"","minHeight":"1200"},model:{value:(_vm.showingReportModal),callback:function ($$v) {_vm.showingReportModal=$$v},expression:"showingReportModal"}},[_c(VForm,{ref:"reportForm",attrs:{"autocomplete":"new-password"},on:{"submit":function($event){$event.preventDefault();return _vm.createReport.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2 pb-0"},[_vm._v(" "+_vm._s(_vm.$t('Crear reporte'))+" ")]),_c(VCardText,[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextarea,{attrs:{"outlined":"","name":"input-7-4","label":_vm.$t('Acuerdos') + '...',"rows":"3","hide-details":""},model:{value:(_vm.form.agreements),callback:function ($$v) {_vm.$set(_vm.form, "agreements", $$v)},expression:"form.agreements"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextarea,{attrs:{"outlined":"","name":"input-7-4","label":_vm.$t('Resultado esperado') + '...',"rows":"3","hide-details":""},model:{value:(_vm.form.expected_result),callback:function ($$v) {_vm.$set(_vm.form, "expected_result", $$v)},expression:"form.expected_result"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTextarea,{attrs:{"outlined":"","name":"input-7-4","label":_vm.$t('Nota') + '...',"rows":"3","hide-details":""},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"terciary","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c(VBtn,{attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Crear reporte'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }